import React, { useState } from "react" 
import * as url from "../../helpers/url_helper"
import { Container, Row, Col, CardBody, Card, CardTitle } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
  
import MetaTags from 'react-meta-tags';
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'

import {
  postJwtLogin,
  getUserProfile,
} from "../../helpers/backend_helper"


const JWT = props => {
    
  function getURLParameters(paramName){
      var sURL = window.document.URL.toString();
      if (sURL.indexOf("?") > 0)
      {
          var arrParams = sURL.split("?");
          var arrURLParams = arrParams[1].split("&");
          var arrParamNames = new Array(arrURLParams.length);
          var arrParamValues = new Array(arrURLParams.length);

          var i = 0;
          for (i = 0; i<arrURLParams.length; i++)
          {
              var sParam =  arrURLParams[i].split("=");
              arrParamNames[i] = sParam[0];
              if (sParam[1] != "")
                  arrParamValues[i] = unescape(sParam[1]);
              else
                  arrParamValues[i] = "No Value";
          }

          for (i=0; i<arrURLParams.length; i++)
          {
              if (arrParamNames[i] == paramName)
              {
                  //alert("Parameter:" + arrParamValues[i]);
                  return arrParamValues[i];
              }
          }
          return "";
      }
  }

  console.log(getURLParameters('token'))
  var token = getURLParameters('token')
  if(token != "" && token.length > 10){
    console.log('JWT ' + token)
    localStorage.setItem("jwt", token)
    const now = new Date().getTime();
    localStorage.setItem('setupTime', now);
    /*
    const user_response = yield call(getUserProfile)
    localStorage.setItem("authUser", JSON.stringify(user_response))
    const userData = JSON.parse(JSON.stringify(user_response));
    window.localStorage.setItem('m_type', userData.user_type);
    window.localStorage.setItem('m_id', userData.id);
    window.localStorage.setItem('m_card', userData.card);    
    */
  }

    return (
      <div className="koloden-main">
        <MetaTags>
            <title>Contact Us | Koloden</title>
            <meta name="description" content="Contact Us | Koloden" />
            <meta property="og:title" content="Contact Us | Koloden" />
        </MetaTags>
        <Help />
        <Header /> 
          <Container style={{"height":"600px"}}>
            <Row className="m-4">
              <Col>
                <h1 className="text-center mt-4">JWT</h1>
              </Col>
            </Row>
          </Container>
        <Footer />
      </div>
    )
  }

export default JWT