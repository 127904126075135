import React from 'react';
import $ from 'jquery';
import {Link, useLocation, withRouter} from 'react-router-dom';
import axio from 'axios';
import { useEffect, useState } from "react";
import ReactGA from 'react-ga';

import '../../../kolodenTheme/css/bootstrap.min.css'
import '../../../kolodenTheme/css/fonts.css'
import '../../../kolodenTheme/css/all.min.css'
import '../../../kolodenTheme/css/style.css'
import logoImage from '../../../assets/images/logo.png'

ReactGA.initialize("UA-78953481-1");

const Header = () => {

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const currentRoute= window.location.pathname;

    const segments = document.URL;
    const currentLink = segments.substr(segments.lastIndexOf('/') + 1);    

    useEffect(() => {
        window.scrollTo(0, 0)
        ReactGA.pageview(window.location.pathname + window.location.search);
        if(localStorage.getItem('authUser')) {
            setIsLoggedIn(true);
        }

        // Check if needs to expires the session
        checkPreviousSession();

    }, [])

    const checkPreviousSession = () => {
        const hours = 2; 
        const now = new Date().getTime();
        const setupTime = localStorage.getItem('setupTime');
        if (setupTime) {
            const timestampDiff = now - setupTime;
            if(timestampDiff > hours*60*60*1000) { // Expires the session after 2 hours
                localStorage.clear()
                setIsLoggedIn(false);
            }
        }
      }
    

    $(function() {
        $('.nav-item').removeClass("mm-active_");
        $('#menu-' + currentLink).addClass("mm-active_");

        $('.nav-item').removeClass("mm-active_");
        $('#menu-' + currentLink).addClass("mm-active_");

        $('.pos-abs-menu').hover(function(){
            $(this).each(function(){
                // $(this).style('background','red');
                $(this).children('ul').show();
            })  
        })
        $('.pos-abs-menu').mouseleave(function(){
            $(this).each(function(){
                $(this).children('ul').hide();
            })  
        })
        
        // $('.pos-abs-menu').hover(function(){ 
        //     $('.pos-abs').show();
        // });
    
        // $('.pos-abs-menu').mouseleave(function(){ 
        //     $('.pos-abs').hide();
        // });

        // $('.navbar-toggler').on('click', function(){
        //     if($(this).hasClass('active_SlideNavBar')){
        //         $(this).removeClass('active_SlideNavBar');
        //         $('#navbarSupportedContent').slideUp();
        //     }
        //     else {
        //         $(this).addClass('active_SlideNavBar');
        //         $('#navbarSupportedContent').slideDown();
        //     }
        // })

        $('.language-btn button').each(function(){
            $(this).on('click', function(){
                if($(this).attr('data-id') == '1'){
                    $('.iframe-video[data-video-id="2"]').hide();
                    $('.iframe-video[data-video-id="1"]').show();
                    $('.language-btn button[data-id="1"]').addClass('active');
                    $('.language-btn button[data-id="2"]').removeClass('active');                    
                }
                else {
                    $('.iframe-video[data-video-id="2"]').show();
                    $('.iframe-video[data-video-id="1"]').hide();
                    $('.language-btn button[data-id="1"]').removeClass('active');
                    $('.language-btn button[data-id="2"]').addClass('active');
                }
            })
        })

        
        $('#close_PopupCstm').on('click', function(){
            $('.popup_CustomModel').hide();
        })
    })
 
        return (
            <div className="header-section">
                <nav className="navbar navbar-expand-lg navbar-light ">
                    <div className="container">
                        <Link to="/home" className="navbar-brand">
                            <img src={logoImage} className="logo" alt="" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li id="menu-pricing" className={splitLocation[1] === "pricing" ? "nav-item active" : "nav-item"}>
                                    <Link to="/pricing" style={{"color": "#444444"}} className="nav-link">Pricing </Link>
                                </li>
                                <li id="menu-about-us" className={splitLocation[1] === "about-us" ? "nav-item pos-abs-menu active" : "nav-item pos-abs-menu"}>
                                    <Link to="/about-us" style={{"color": "#444444"}} className="nav-link">About Us</Link>
                                        <ul className="navbar-nav ml-auto sub-menu pos-abs" style={{"display": "none"}}>
                                            <li id="intellectual-property-management" className={splitLocation[2] === "intellectual-property-management-software-api" ? "nav-item active" : "nav-item"}>
                                                <Link to="/page/intellectual-property-management-software-api" style={{"color": "#444444!important"}} className="nav-link">API</Link>
                                            </li>
                                            <li id="ip-management-software" className={splitLocation[2] === "ip-management-software" ? "nav-item active" : "nav-item"}>
                                                <Link to="/page/ip-management-software" style={{"color": "#444444!important"}} className="nav-link">Reporting</Link>
                                            </li>

                                            <li id="about-our-product-secure" className={splitLocation[2] === "koloden-is-secure-utilizing-256-bit-ssl" ? "nav-item active" : "nav-item"}>
                                                <Link to="/page/koloden-is-secure-utilizing-256-bit-ssl" style={{"color": "#444444!important"}} className="nav-link">Security</Link>
                                            </li>
                                            <li id="ecommerce-brand-protection-solution" className={splitLocation[2] === "e-commerce-brand-protection-solution-koloden" ? "nav-item active" : "nav-item"}>
                                                <Link to="/page/e-commerce-brand-protection-solution-koloden" style={{"color": "#444444!important"}} className="nav-link">Software</Link>
                                            </li>
                                        </ul>
                                </li>
                                <li id="menu-use-cases" className={splitLocation[1] === "use-cases" ? "nav-item active" : "nav-item"}>
                                    <Link to="/use-cases" style={{"color": "#444444"}} className="nav-link">Use Cases</Link>
                                </li>
                                {/*
                                <li id="menu-api" className={splitLocation[1] === "api" ? "nav-item active" : "nav-item"}>
                                    <Link to="/api" style={{"color": "#444444"}} className="nav-link">API</Link>
                                </li>
                                */}
                                <li id="menu-api" className={splitLocation[1] === "blogs" ? "nav-item active" : "nav-item"}>
                                    <Link to="/blogs" style={{"color": "#444444"}} className="nav-link">Blogs</Link>
                                </li>
                                <li id="menu-api" className={splitLocation[1] === "faqs" ? "nav-item pos-abs-menu active" : "nav-item pos-abs-menu"}>
                                    <Link to="/faqs" style={{"color": "#444444"}} className="nav-link">FAQs</Link>
                                    <ul className="navbar-nav ml-auto sub-menu pos-abs" style={{"display": "none"}}>
                                        <li id="is-dropshipping-legal" className={splitLocation[2] === "is-dropshipping-legal-how-it-works-protecting-your-assets" ? "nav-item active" : "nav-item"}>
                                            <Link to="/page/is-dropshipping-legal-how-it-works-protecting-your-assets" style={{"color": "#444444!important"}} className="nav-link">Is Dropshipping Legal</Link>
                                        </li>
                                        <li id="how-to-avoid-trademark" className={splitLocation[2] === "how-to-avoid-trademark-infringement-3-simple-steps" ? "nav-item active" : "nav-item"}>
                                            <Link to="/page/how-to-avoid-trademark-infringement-3-simple-steps" style={{"color": "#444444 !important"}} className="nav-link">How To Avoid Trademark Infringement</Link>
                                       </li>
                                    </ul>
                                </li>
                                {/* <li id="menu-api" className={splitLocation[1] === "post" ? "nav-item active" : "nav-item"}>
                                    <Link to="/page/how-to-avoid-trademark-infringement-3-simple-steps" style={{"color": "#444444"}} className="nav-link">How To Avoid Trademark Infringement</Link>
                                </li> */}
                            </ul>
                            {isLoggedIn ?  ( 
                                <ul className="navbar-nav">
                                    <li id="menu-dashboard" className="nav-item">
                                        <Link to="/dashboard" style={{"color": "#444444!important"}} className="nav-link">Dashboard</Link>
                                    </li>
                                    <li id="menu-logout" className="nav-item">
                                        <Link to="/logout" style={{"color": "#444444!important"}} className="nav-link">Logout</Link>
                                    </li>
                                </ul>
                             ): (
                                <ul className="navbar-nav">
                                    <li id="menu-login" className="nav-item">
                                        <Link to="/login" style={{"color": "#444444"}} className="nav-link">Sign In</Link>
                                    </li>
                                    <li id="menu-register" className="nav-item get-started">
                                        <Link to="/register" style={{"color": "#444444",}} className="nav-link" href="">Get Started</Link>
                                    </li>
                                </ul>
                             )}
                        </div>
                    </div>
                </nav>
            </div>
        );
};

export default withRouter(Header);
