import React from "react"
import { Redirect } from "react-router-dom"
// Dashboard
import Dashboard from "../pages/Dashboard/index"

import UserStats from "../pages/UserStats/index"
import UserManage from "../pages/UserStats/manage"
import ModifyCriticalList from "../pages/ModifyCriticalList/manage"
import UserReferral from "../pages/UserStats/referral"

import UserPromocode from "../pages/UserStats/promocode"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import EditAccount from "../pages/EditAccount/index"
import Billing from "../pages/Billing/index"
import Tester from "../pages/Tester/index"
import addPaymentMethod from "../pages/addPaymentMethod/index"
import CancelSubscription from "../pages/CancelSubscription/index"
import Notifications from "../pages/Notifications/index"
import RiskFeed from '../pages/RiskFeed/index'


import UserProfile from "../pages/Authentication/user-profile"
import Amazon from "../pages/Amazon/index"
import eBay from "../pages/eBay/index"
import Wish from "../pages/Wish/index"
import Walmart from "../pages/Walmart/index"
import addStore from '../pages/AddStore/index';
import addStoreSp from '../pages/AddStore/index-sp';

// Site
import Home from '../pages/Site/Home';
import Keys from '../pages/Site/Keys';
import Pricing from '../pages/Site/Pricing';
import TermsOfService from '../pages/Site/TermsOfService';
import RightSholders from '../pages/Site/RightSholders';
import Contact from '../pages/Site/Contact';
import Api from '../pages/Site/Api';
import AboutUs from '../pages/Site/AboutUs';
import FAQs from '../pages/Site/FAQs';
import Blogs from '../pages/Site/Blogs';
import Blog from '../pages/Site/Blog';
import Page from '../pages/Site/Page';
import UseCases from '../pages/Site/UseCases';
import Privacy from '../pages/Site/Privacy';
import PasswordResetForm from '../pages/Site/PasswordResetForm';
import PaymentConfirmation from "../pages/Site/PaymentConfirmation"
import AmazonPaymentAuthorization from "../pages/Site/AmazonPaymentAuthorization"
import AmazonAuthorizationFailed from "../pages/Site/AmazonAuthorizationFailed"
import StoreAuthorizationSuccess from "../pages/AddStore/store-success"
import StoreAuthorizationError from "../pages/AddStore/store-error"
import JWT from "../pages/Site/Jwt"

const userRoutes = [
  
  { path: "/dashboard",  exact: true, component: Dashboard },
  { path: "/dashboard/user-totals",  exact: true, component: UserStats },
  { path: "/dashboard/manage-users",  exact: true, component: UserManage },
  { path: "/dashboard/referrals",  exact: true, component: UserReferral },
  { path: "/dashboard/promocode",  exact: true, component: UserPromocode },
  { path: "/dashboard/modify-critical-list",  exact: true, component: ModifyCriticalList },

  // //profile
  { path: "/profile",  exact: true, component: UserProfile },
  // //editaccount
  { path: "/editaccount",  exact: true, component: EditAccount },
  // //Amazon
  { path: "/amazon",  exact: true,component: Amazon },
  { path: "/ebay",  exact: true, component: eBay },
  { path: "/walmart",  exact: true,component: Walmart },
  { path: "/wish",  exact: true, component: Wish },
  // //Billing
  { path: "/billing",  exact: true, component: Billing },

  // //Tester
  { path: "/tester",  exact: true, component: Tester },

  // //add store route
  { path: "/add-store",  exact: true, component: addStoreSp },
  { path: "/add-store-sp",  exact: true, component: addStoreSp },
  { path: "/store-success",  exact: true, component: StoreAuthorizationSuccess },
  { path: "/store-error",  exact: true, component: StoreAuthorizationError },
  
  // //route for wish store to get the authtoken
  { path: "/add-store/code:",  exact: true, component: addStore },
  // //addPaymentMethod
  { path: "/payment-method",  exact: true, component: addPaymentMethod },  
  // //Cancel Subscription
  { path: "/cancel-subscription",  exact: true, component: CancelSubscription },  
  // //Notifications
  { path: "/notifications",  exact: true, component: Notifications },  
  // //Risk Feed
  { path: "/riskfeed",  exact: true, component: RiskFeed },  

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },
]

const authRoutes = [

  { path: "/logout",  exact: true, component: Logout },
  { path: "/login",  exact: true, component: Login },
  { path: "/forgot-password",  exact: true, component: ForgetPwd },
  { path: "/register",  exact: true, component: Register },

  // Site - Home
  { path: "/home", exact: true, component: Home },
  { path: "/keys", exact: true, component: Keys },
  { path: "/pricing", exact: true, component: Pricing },
  { path: "/terms-of-service", exact: true, component: TermsOfService },
  { path: "/rightsholders", exact: true, component: RightSholders },
  { path: "/contact-us", exact: true, component: Contact },
  { path: "/api", exact: true, component: Api },
  { path: "/about-us", exact: true, component: AboutUs },
  { path: "/faqs", exact: true, component: FAQs },
  { path: "/blogs/:page?", exact: true, component: Blogs },
  { path: "/page/:page", exact: true, component: Page },
  { path: "/post/:slug", exact: true, component: Blog },
  { path: "/use-cases", exact: true, component: UseCases },
  { path: "/privacy", exact: true, component: Privacy },
  { path: "/resetpassword/:token", exact: true, component: PasswordResetForm},
  { path: "/payment-completed", exact:true, component: PaymentConfirmation },
  { path: "/amazon-pay-authorization", exact:true, component: AmazonPaymentAuthorization },
  { path: "/amazon-payment-failed", exact:true, component: AmazonAuthorizationFailed },
  { path: "/jwt", exact:true, component: JWT },
]

export { userRoutes, authRoutes }